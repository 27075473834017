<template>
  <b-modal
    ref="lottoset-modal"
    hide-footer
    title="ตั้งค่าหวยชุด"
    ok-variant="success"
    @show="setData"
    @hidden="hideModal"
  >
    <form
      id="market-set-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-form-group
        label="ชื่อใช้งาน"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="ชื่อใช้งาน"
          disabled
          :value="username"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="หวย"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="หวย"
          disabled
          :value="marketTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ราคา"
      >
        <b-form-input
          type="text"
          v-model="input.price"
          placeholder="ราคาขาย บาท/ชุด"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ส่วนลด"
      >
        <b-form-input
          type="text"
          v-model="input.discount"
          placeholder="ส่วนลด บาท/ชุด"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <fieldset class="form-group">
        <legend class="bv-no-focus-ring col-form-label pt-0">ราคาจ่าย: <span class="text-primary">{{ rateTitle }}</span></legend>
        <table class="table table-sm table-bordered table-info mb-0">
          <thead>
            <tr>
              <th width="60%" class="text-center">ประเภท</th>
              <th class="text-center">จ่าย (บาท)</th>
            </tr>
          </thead>
          <tbody class="bg-light text-dark">
            <tr v-for="prize in marketOpenBets" :key="prize.code">
              <td class="text-center">{{prize.text}}</td>
              <td>
                <b-form-input
                  type="text"
                  v-model="input.openBets[prize.code].pay"
                  size="sm"
                  :number="true"
                  required
                ></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>

      <!-- <div class="alert alert-danger p-2">
        <b-form-checkbox name="check-button" v-model="setChildAccount"> ตั้งค่าให้สมาชิกทั้งหมด </b-form-checkbox>
      </div> -->

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import Swal from 'sweetalert2'
import AccountService from "@/services/accountService"
import { marketTypes } from '@/configs/market.config'

export default {
  name: 'EditLottosetModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    market: {
      type: Object,
      default: null
    },
    rate: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      input: {},
      setChildAccount: false,
      isProcess: false,
      needReload: false
    }
  },
  computed: {
    accountId() {
      return this.account?._id
    },
    username() {
      return this.account?.user?.username
    },
    marketId() {
      return this.market?._id
    },
    marketTitle() {
      return this.market?.marketTitle
    },
    marketOpenBets() {
      if(!this.market || !this.account)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((o)=>{
        return this.isAvailable(o.code)
      })
    },
    rateId() {
      return this.rate?._id
    },
    rateTitle() {
      return this.rate?.rateTitle
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    isAvailable(key) {
      return this.market?.openBets?.[key] && this.rate?.openBets?.[key]?.isAvailable
    },
    setData() {
      this.needReload = false
      this.input = JSON.parse(JSON.stringify(this.account.sets[this.marketId].rates[this.rateId]))
    },
    showModal() {
      this.$refs['lottoset-modal'].show()
    },
    hideModal() {
      this.$refs['lottoset-modal'].hide()
    },
    handleSubmit() {
      this.save({
        accountIds: [this.accountId],
        updateChild: this.setChildAccount,
        update: {
          marketId: this.marketId,
          marketTitle: this.marketTitle,
          rate: this.input
        }
      })
    },
    save(data) {
      AccountService.updateAccountSettingLottosets(data)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>
.table {
  tr {
    td {
      vertical-align: middle;
    }
  }
}
</style>
