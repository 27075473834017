<template>
  <div class="container-fluid user-setting-lottosets">
    <div class="page-header mb-1">
      <strong>ตั้งค่าหวยชุด</strong>
    </div>
    <div class="card">

      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="market in markets" :key="market._id" class="nav-item">
            <a class="nav-link" :class="[{'active':tabActiveMarketId===market._id}]" href="#" target="_self" @click="tabActiveMarketId=market._id">{{ market.marketTitle }}</a>
          </li>
        </ul>
      </div>

      <div class="payrate-options p-1">
        <button
          v-for="rate in rates"
          class="btn m-1"
          :key="rate._id"
          :class="[{'btn-primary':tabActiveRateId===rate._id}, {'btn-light text-primary':tabActiveRateId!==rate._id}]"
          @click="tabActiveRateId=rate._id"
          v-if="rate.status === 'Active'"
        >{{ rate.rateTitle }}</button>
      </div>

      <div class="p-2">
        <table class="table-accounts table table-hover table-bordered mb-0">
          <thead>
            <tr>
              <th class="text-center" rowspan="2">ลำดับ</th>
              <th class="text-center" rowspan="2">ชื่อใช้งาน</th>
              <th class="text-center" rowspan="2">ระดับ</th>
              <th class="text-center" rowspan="2">เปิด/ปิด</th>
              <th class="text-center" rowspan="2">ราคา<br />(บาท)</th>
              <th class="text-center" rowspan="2">ส่วนลด<br />(%)</th>
              <th :colspan="marketOpenBets.length" class="text-center">จ่าย (บาท)</th>
              <th class="text-center" rowspan="2">แก้ไข</th>
            </tr>
            <tr>
              <th
                v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-center"
              >{{ bet.text }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in accounts" :key="account._id">
              <td class="text-center">{{ index+1 }}</td>
              <td class="text-center">{{ account.user.username }}</td>
              <td class="text-center">{{ account.level.levelName }}</td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="account.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable"
                  name="check-button"
                  switch
                  @change="toggleChange(account)"
                  :class="[account.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable ? 'text-success' : 'text-danger']"
                >
                  {{ account.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td class="text-right text-success" :class="[{'alert-dark': !account.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}]">{{ account.sets[tabActiveMarketId].rates[tabActiveRateId].price | amountFormat(2, '0.00') }}</td>
              <td class="text-right text-danger" :class="[{'alert-dark': !account.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}]">{{ account.sets[tabActiveMarketId].rates[tabActiveRateId].discount | amountFormat(2, '0.00') }}</td>

              <td
                v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-right text-info"
                :class="[{'alert-dark': !account.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}]"
              >
                {{ account.sets[tabActiveMarketId].rates[tabActiveRateId].openBets[bet.code].pay | amountFormat(2, '0.00') }}
              </td>

              <td class="text-center" :class="[{'alert-dark': !account.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable}]">
                <button :disabled="!account.sets[tabActiveMarketId].rates[tabActiveRateId].isAvailable" class="btn btn-sm btn-outline-primary" @click="toggleSetting(account)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!accounts.length">
              <td class="text-center" :colspan="marketOpenBets.length + 8">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <EditLottosetModal :is-show="isShowModal" :market="market" :rate="rate" :account="editAccount" @close="modalClosed" />
  </div>
</template>
<script>
import AccountService from "@/services/accountService"
import MarketService from '@/services/marketService'
import Swal from 'sweetalert2'
import { marketTypes } from '@/configs/market.config'
import EditLottosetModal from './components/EditLottosetModal'

export default {
  name: 'Lottosets',
  components: {
    EditLottosetModal
  },
  data() {
    return {
      data: null,
      tabActiveMarketId: null,
      tabActiveRateId: null,
      isShowModal: false,
      editAccount: null
    }
  },
  computed: {
    markets() {
      return this.data?.markets || []
    },
    market() {
      if(!this.tabActiveMarketId)
        return null

      return this.markets.find(market=>market._id===this.tabActiveMarketId)
    },
    rates() {
      const rates = (this.data?.rates || []).filter((r)=>{
        return r.marketSet === this.market?.marketSet
      })

      if(!this.tabActiveRateId || !rates.map(r=>r._id).includes(this.tabActiveRateId))
        this.tabActiveRateId = rates?.[0]?._id || null

      return rates
    },
    rate() {
      return this.rates.find((r)=>{
        return r._id === this.tabActiveRateId
      })
    },
    marketOpenBets() {
      if(!this.market)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((o)=>{
        return this.isAvailable(o.code)
      })
    },
    accounts() {
      return (this.data?.accounts || [])
      // .filter((acc)=>{
      //   const groupId = this.market?.groupId ?? null
      //   if(!groupId)
      //     return false

      //   return acc?.groups?.[groupId]?.isAvailable && (acc.markets?.[this.tabActiveMarketId]?.isAvailable ?? true)
      // })
    }
  },
  methods: {
    loadData() {
      AccountService.getAccountSettingLottosets()
      .then((response)=>{
        if(response.success) {
          this.data = response.data

          if(!this.tabActiveMarketId) {
            this.tabActiveMarketId = this.data.markets[0]?._id
          }
        }
      })
    },
    isAvailable(key) {
      return this.market?.openBets?.[key] && this.rate?.openBets?.[key]?.isAvailable
    },
    toggleChange(account) {
      this.save({
        accountIds: [account._id],
        updateChild: false,
        update: {
          marketId: account.sets[this.tabActiveMarketId].marketId,
          marketTitle: account.sets[this.tabActiveMarketId].marketTitle,
          rate: account.sets[this.tabActiveMarketId].rates[this.tabActiveRateId]
        }
      })
    },
    save(data) {
      AccountService.updateAccountSettingLottosets(data)
      .then((response)=>{
        if(!response.success) {
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadData()
      })
    },
    toggleSetting(account) {
      this.editAccount = account
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      this.editAccount = null
      if(needReload) {
        this.loadData()
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style lang="scss" scoped>
.user-setting-lottosets {
  table {
    th {
      border-top: 0;
      font-size: 90%;
      vertical-align: middle;
    }
    td {
      vertical-align: middle;
    }
  }
}
</style>
